import { graphql } from "gatsby";

import {
  SectionContainer,
  SectionWrapper
} from "../common/Section";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import { CallToAction, LargeCallToAction } from "../components/CallToAction";
import { BenefitsCarousel, TestimonialsCarousel } from "../components/Carousel";
import { ContactHero } from "../components/Hero";


interface Props {
  data: any
};


const ContactPage = ({
  data
}: Props ) => {
  const benefits = data?.allPrismicBenefit?.edges || [];
  const socials = data?.allPrismicSocial?.edges || [];
  const testimonials = data?.allPrismicTestimonial?.edges || [];

  const calltoaction = data?.prismicCallToAction?.data || {};
  const global = data?.prismicSiteInfo?.data || {};

  const contactpage = data?.prismicContactPage?.data || {};

  return (
    <Layout
      global={ global }
      socials={ socials }
    >
      <Head
        title={ contactpage.title.text }
        description={ contactpage.description.text }
      />

      <SectionWrapper>
        <SectionContainer>
          <ContactHero
            heading={ contactpage.hero_heading.text }
            description={ contactpage.hero_description.html }
            global={ global }
            socials={ socials }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
      >
        <LargeCallToAction
          heading={ contactpage.large_cta_heading.text }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="92px 0 0"
      >
        <SectionContainer>
          <BenefitsCarousel
            nodes={ benefits }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <TestimonialsCarousel
          heading={ contactpage.testimonials_heading.text }
          description={ contactpage.testimonials_description.html }
          nodes={ testimonials }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading={ calltoaction.heading.text }
            description={ calltoaction.description.html }
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query ContactPageQuery {
    allPrismicBenefit {
      edges {
        node {
          _previewable
          data {
            benefit_heading {
              text
            }
            benefit_icon
            benefit_description {
              text
            }
          }
          uid
        }
      }
    }

    allPrismicSocial {
      edges {
        node {
          _previewable
          data {
            platform
            link {
              url
            }
          }
          uid
        }
      }
    }

    allPrismicTestimonial {
      edges {
        node {
          _previewable
          data {
            author {
              text
            }
            review {
              text
            }
            source
          }
        }
      }
    }

    prismicCallToAction {
      _previewable
      data {
        heading {
          text
        }
        description {
          html
        }
      }
      uid
    }

    prismicSiteInfo {
      _previewable
      data {
        phone_number {
          text
        }
        phone_number_link {
          url
        }

        email {
          text
        }
        email_link {
          url
        }

        address {
          text
        }

        accreditations {
          image {
            alt
            gatsbyImageData(
              height: 40
              placeholder: BLURRED
            )
          }
        }

        copyright {
          text
        }
      }
      uid
    }

    prismicContactPage {
      _previewable
      data {
        title {
          text
        }
        description {
          text
        }

        hero_heading {
          text
        }
        hero_description {
          html
        }

        large_cta_heading {
          text
        }

        testimonials_heading {
          text
        }
        testimonials_description {
          html
        }
      }
    }
  }
`;


export default ContactPage;